var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"column":""}},[_c('ApplicationsTable',{attrs:{"action":() => {},"action-icon":"edit","headers":_vm.headers,"items":_vm.applications,"loading":_vm.loading,"title":_vm.$t('application.list')},scopedSlots:_vm._u([(!_vm.loading)?{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"color":"warning","icon":"warning","value":true}},[_vm._v(" "+_vm._s(_vm.$t('application.no_subscription_found'))+" ")])]},proxy:true}:null,{key:"last-row",fn:function({ item: applicationItem }){return [_c('td',{style:(`display: grid; grid-template-columns: ${_vm.isAdmin ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}`)},[_c('div',[(
							(
								applicationItem.can_be_whitelabelled 
								|| applicationItem.holding_id
								|| applicationItem.settings.is_configurable
							) && _vm.isAdmin
						)?_c('w-btn',{attrs:{"color":"","disabled":applicationItem.deleted_at,"flat":"","icon":"edit","mini":"","loading":_vm.modifyLoading == applicationItem.id},on:{"click":function($event){return _vm.showApplicationSetUp(applicationItem)}}},[_vm._v(_vm._s(_vm.$t('application.modify_application')))]):_vm._e()],1),_c('div',[(applicationItem.is_for_companies)?_c('w-btn',{attrs:{"color":"","disabled":applicationItem.deleted_at,"flat":"","icon":"settings","mini":""},on:{"click":function($event){return _vm.openApplicationVendorDialog(applicationItem)}}},[_vm._v(_vm._s(_vm.$t('application.setup_application')))]):_vm._e()],1),(_vm.isAdmin)?_c('div',[_c('w-btn',{attrs:{"color":"","flat":"","icon":"remove","loading":applicationItem.deleted_at,"mini":""},on:{"click":function($event){return _vm.openApplicationRemovalDialog(applicationItem)}}},[_vm._v(_vm._s(_vm.$t('application.remove_application')))])],1):_vm._e()])]}}],null,true)}),_c('SubscriptionDialog',{attrs:{"application":_vm.application,"value":_vm.dialog},on:{"input":_vm.closeDialogs}}),_c('ApplicationCreationDialog',{attrs:{"service":_vm.service,"app":_vm.application,"is-creation":false},model:{value:(_vm.displayApplicationUpdateDialog),callback:function ($$v) {_vm.displayApplicationUpdateDialog=$$v},expression:"displayApplicationUpdateDialog"}}),_c('ApplicationRemovalDialog',{attrs:{"api-call":_vm.applicationRemovalApiCall,"application":_vm.application,"value":_vm.applicationRemovalDialog},on:{"input":_vm.closeDialogs}}),_c('ApplicationVendorDialog',{attrs:{"application":_vm.application,"value":_vm.applicationVendorDialog},on:{"input":_vm.closeDialogs}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }