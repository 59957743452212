<template>
	<v-layout column>
		<ApplicationsTable :action="() => {}" action-icon="edit" :headers="headers" :items="applications" :loading="loading" :title="$t('application.list')">
			<template v-if="!loading" v-slot:no-data>
				<v-alert color="warning" icon="warning" :value="true">
					{{ $t('application.no_subscription_found') }}
				</v-alert>
			</template>
			<template v-slot:last-row="{ item: applicationItem }">
				<td :style="`display: grid; grid-template-columns: ${isAdmin ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}`">
					<div>
						<w-btn
							v-if="
								(
									applicationItem.can_be_whitelabelled 
									|| applicationItem.holding_id
									|| applicationItem.settings.is_configurable
								) && isAdmin
							"
							color=""
							:disabled="applicationItem.deleted_at"
							flat
							icon="edit"
							mini
							:loading="modifyLoading == applicationItem.id"
							@click="showApplicationSetUp(applicationItem)"
							>{{ $t('application.modify_application') }}</w-btn
						>
					</div>
					<div>
						<w-btn
							v-if="applicationItem.is_for_companies"
							color=""
							:disabled="applicationItem.deleted_at"
							flat
							icon="settings"
							mini
							@click="openApplicationVendorDialog(applicationItem)"
							>{{ $t('application.setup_application') }}</w-btn
						>
					</div>
					<div v-if="isAdmin">
						<w-btn color="" flat icon="remove" :loading="applicationItem.deleted_at" mini @click="openApplicationRemovalDialog(applicationItem)">{{
							$t('application.remove_application')
						}}</w-btn>
					</div>
				</td>
			</template>
		</ApplicationsTable>
		<SubscriptionDialog :application="application" :value="dialog" @input="closeDialogs" />
		<ApplicationCreationDialog v-model="displayApplicationUpdateDialog" :service="service" :app="application" :is-creation="false" />
		<ApplicationRemovalDialog :api-call="applicationRemovalApiCall" :application="application" :value="applicationRemovalDialog" @input="closeDialogs" />
		<ApplicationVendorDialog :application="application" :value="applicationVendorDialog" @input="closeDialogs" />
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'UsedApplicationsTable',
	components: {
		ApplicationRemovalDialog: () => ({
			component: import('@/components/Applications/ApplicationRemovalDialog')
		}),
		ApplicationsTable: () => ({
			component: import('@/components/Applications/ApplicationsTable')
		}),
		SubscriptionDialog: () => ({
			component: import('@/components/Applications/SubscriptionDialog')
		}),
		ApplicationVendorDialog: () => ({
			component: import('@/components/Applications/ApplicationVendorDialog')
		}),
		ApplicationCreationDialog: () => ({
			component: import('@/components/Applications/ApplicationCreationDialog')
		})
	},
	mixins: [ApplicationsModuleGuard],
	data: function () {
		return {
			application: null,
			applications: [],
			applicationRemovalDialog: false,
			applicationVendorDialog: false,
			dialog: false,
			displayApplicationUpdateDialog: false,
			headers: [
				{ align: 'center', sortable: false, text: this.$t('application.headers.logo'), value: 'logo', width: '100px' },
				{ align: 'center', text: this.$t('application.headers.name'), value: 'title' },
				{ align: 'center', text: this.$t('application.headers.description'), value: 'desc' },
				{ align: 'center', sortable: false, text: this.$t('application.headers.info'), value: 'info', width: '180px' },
				{ align: 'center', sortable: false, text: this.$t('application.headers.actions'), value: 'uri', width: '140px' }
			],
			loading: false,
			modifyLoading: false
		}
	},
	computed: {
		applicationRemovalApiCall: function () {
			return function () {
				return this.service.deleteSubscription(this.selectedAccountingFirm.id, this.application.id).then(() => {
					this.application.deleted_at = true
					this.application.delete_at_timeout = this.checkIfApplicationExists(this.application.id)
					this.appEventBus.emit(
						this.appEvents.SNACKBAR_SUCCESS,
						this.$t('application.events.application_being_removed', { application: this.application.title })
					)
				})
			}.bind(this)
		},
		isAdmin: function () {
			return this.selectedAccountingFirm?.isAccountantAdmin ?? false
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'loadApplications'
		}
	},
	beforeDestroy: function () {
		this.emptyApplicationsList()
	},
	mounted: function () {
		this.loadApplications()
	},
	methods: {
		checkIfApplicationExists: function (subscriptionId) {
			return setTimeout(
				function () {
					this.service
						.getApplicationSubscription(
							this.accountingFirmId,
							subscriptionId,
							{
								only_trashed: true
							},
							{ show_error: false }
						)
						.then(() => {
							const application = this.applications.find(({ id }) => id == subscriptionId)
							if (!application) {
								return
							}
							application.delete_at_timeout = this.checkIfApplicationExists(subscriptionId)
							return application.delete_at_timeout
						})
						.catch(() => {
							const index = this.applications.findIndex(({ id }) => id == subscriptionId)
							if (index != -1) {
								this.applications.splice(index, 1)
							}
							this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_DELETED, subscriptionId)
						})
				}.bind(this),
				3000
			)
		},
		closeDialogs: function () {
			this.applicationRemovalDialog = false
			this.applicationVendorDialog = false
			this.dialog = false
			this.application = null
			this.displayApplicationUpdateDialog = false
		},
		emptyApplicationsList: function () {
			this.applications.forEach(application => {
				if (application.deleted_at_timeout) {
					clearTimeout(application.deleted_at_timeout)
				}
			})
			this.applications.splice(0, this.applications.length)
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.APPLICATION_SUBSCRIPTION_CREATED, action: this.loadApplications },
				{ event: this.events.APPLICATION_UPDATED, action: this.loadApplications },
				{ event: this.events.APPLICATION_SUBSCRIPTION_UPDATED, action: this.loadApplications },
				{ event: this.events.UPDATE_APPLICATION_VENDOR, action: this.updateApplicationVendor }
			]
		},
		loadApplications: function () {
			this.loading = true
			return this.service
				.listSubscriptions(this.accountingFirmId)
				.then(applications => {
					this.emptyApplicationsList()
					applications.forEach(application => {
						this.applications.push(application)
						if (application.deleted_at) {
							application.delete_at_timeout = this.checkIfApplicationExists(application.id)
						}
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		openApplicationRemovalDialog: function (application) {
			this.application = application
			this.applicationRemovalDialog = true
		},
		onSubscriptionCreated: function () {
			this.loadApplications()
		},
		onSubscriptionUpdated: function (subscription) {
			this.applications.forEach(applicationInList => {
				if (applicationInList.id == subscription.id) {
					applicationInList.title = subscription.title
				}
			})
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.actions.updated', { name: subscription.title }))
		},
		openApplicationVendorDialog: function (application) {
			this.application = application
			this.applicationVendorDialog = true
		},
		showApplicationSetUp: function (application) {
			this.application = application

			if (this.application.can_be_whitelabelled || this.application.settings.is_configurable) {
				this.modifyLoading = this.application.id
				this.service.listSubscriptionParameters(this.accountingFirmId, this.application.id)
					.then(parameters => {
						this.application.params_accounting_firm = parameters
						this.dialog = true
					}).finally(() => {this.modifyLoading = false})
			} else if (this.application.holding_id) {
				this.displayApplicationUpdateDialog = true
			}

			return Promise.resolve()
		},
		updateApplicationVendor: function (applicationVendor) {
			const id = applicationVendor.id
			const application = this.applications.find(application => application.id == id)
			if (!application) {
				return
			}

			Object.assign(application, applicationVendor)
		}
	}
}
</script>
